import React, { useContext, useEffect, useState } from "react";

import StateContext from "../StateContext";

import DispatchContext from "../DispatchContext";

import RemoveHours from "./RemoveHours";

import moment from "moment";
import "moment/locale/da";

const InputComponent = ({ Day, Input, jobid }) => {
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);

  const useruid = appState.user.user.uid;

  const weeknum = moment(Day).format("w");

  const jobArr = appState.objArr;

  const Dato = Day.format("D-M-YYYY");
  const SelectedId = jobid ? jobid : appState.SelectedId;

  const UpdateChange = (e) => {
    let val = typeof e === "object" ? e.target.value : e;

    const selId = {
      jobid: SelectedId,
      jobtitle: appState.SelectedTitle,
      dato: Dato,
      dag: moment.weekdays(moment(Day).day()),
      uge: moment(Day).format("w"),
      timer: val,
      nomineltimer: appState.WorkingHourOnDay[moment(Day).day()],
      createdAt: Date.now(),
    };

    let arrId = jobArr[0][useruid];

    console.log("target value ", e.target.value);

    let found = false;
    for (let index in arrId) {
      if (arrId[index].jobid === SelectedId && arrId[index].dato === Dato) {
        val > 0 || val === "" ? appDispatch({ type: "Change_Hours", index: index, value: val }) : appDispatch({ type: "Remove_Hours", index: index });

        found = true;
        break;
      }
    }

    if (!found) {
      appDispatch({ type: "Add_Hours", value: selId });
    }
  };

  const handleHourChange = (e) => {
    const delay = setTimeout(UpdateChange(e), 2000);

    return () => clearTimeout(delay);
  };

  return (
    <>
      <input
        type="text"
        className="day__summary--lightblue"
        id={`${Dato}_${SelectedId}`}
        name={moment.weekdays(moment(Day).day())}
        size="3"
        onChange={handleHourChange}
        defaultValue={Input}
      />
    </>
  );
};
export default InputComponent;
