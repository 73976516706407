import React, { useContext, useEffect, useState } from "react";

import moment from "moment";
import "moment/locale/da";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStopwatch, faTrash, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import StateContext from "../StateContext";
import DispatchContext from "../DispatchContext";

import InputComponent from "./InputComponent";

import DateHourErrorAlert from "./DateHourErrorAlert";

const DayComponent = ({ Day }) => {
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);

  const [updatepossible, setUpdatePossible] = useState(true);

  const [currentdaysum, setCurrentDaySum] = useState();

  const [datewitherrors, setDateWithErrors] = useState();

  const [input, setInput] = useState();

  const useruid = appState.user.user.uid;

  const weeknum = moment(Day).format("w");

  const jobArr = appState.objArr;

  const Dato = Day.format("D-M-YYYY");
  const SelectedId = appState.SelectedId;

  const deleteOnclick = (delJobid, delDate) => {
    let arrId = jobArr[0][useruid];

    let ID = delDate !== undefined ? delJobid : SelectedId;
    let DATE = delDate !== undefined ? delDate : Dato;

    for (let index in arrId) {
      if (arrId[index].jobid === ID && arrId[index].dato === DATE) {
        appDispatch({ type: "Remove_Hours", index: index });
      }
    }

    appDispatch({ type: "setClicks" });
  };

  //prevent adding more hours to days with to many allready
  useEffect(() => {
    if (currentdaysum > appState.maxWorkingHoursDay) {
      setUpdatePossible(false);
    }
  }, [currentdaysum]);

  useEffect(() => {
    let currentday = jobArr[0][useruid].filter((val, index) => {
      return val.dato === Dato;
    });

    setDateWithErrors(currentday);
  }, [Dato, appState.setClicks]);

  useEffect(() => {
    let currentweek = jobArr[0][useruid].filter((val) => {
      return val.uge === weeknum;
    });

    let currentday = jobArr[0][useruid].filter((val) => {
      return val.dato === Day.format("D-M-YYYY");
    });

    let currentProjectInWeek = jobArr[0][useruid].filter((val) => {
      return val.uge === weeknum && val.jobid === appState.SelectedId;
    });

    let currentProjectWeekSum = currentProjectInWeek.reduce((acc, val) => {
      return (acc += Number(val.timer));
    }, 0);

    let currentweeksum = currentweek.reduce((acc, val) => {
      return (acc += Number(val.timer));
    }, 0);

    let currentdaySum = currentday.reduce((acc, val) => {
      return (acc += Number(val.timer));
    }, 0);

    let total = jobArr[0][useruid].reduce((acc, val) => {
      return (acc += Number(val.timer));
    }, 0);

    appDispatch({ type: "ProjectInWeekSum", value: currentProjectWeekSum });
    setCurrentDaySum(currentdaySum);
    appDispatch({ type: "WeekSum", value: currentweeksum });
    appDispatch({ type: "TotalHours", value: total });
  }, [SelectedId, appState.setClicks]);

  const addFullDayOnProject = (e) => {
    const selId = {
      jobid: SelectedId,
      jobtitle: appState.SelectedTitle,
      dato: Dato,
      dag: moment.weekdays(moment(Day).day()),
      uge: moment(Day).format("w"),
      timer: appState.WorkingHourOnDay[moment(Day).day()],
      nomineltimer: appState.WorkingHourOnDay[moment(Day).day()],
      createdAt: Date.now(),
    };

    let arrId = jobArr[0][useruid];

    let found = false;
    for (let index in arrId) {
      if (arrId[index].jobid === SelectedId && arrId[index].dato === Dato) {
        appDispatch({ type: "Change_Hours", index: index, value: appState.WorkingHourOnDay[moment(Day).day()] });
        found = true;
        break;
      }
    }
    if (!found) {
      appDispatch({ type: "Add_Hours", value: selId });
    }

    appDispatch({ type: "setClicks" });
  };

  useEffect(() => {
    let arrId = jobArr[0][useruid];

    for (let index in arrId) {
      if (arrId[index].jobid === SelectedId && arrId[index].dato === Dato) {
        setInput(arrId[index].timer);
      }
    }
  }, [Day, SelectedId, appState.setClicks]);

  return (
    <>
      {/*  <div>{Dato}</div>
      <div>{moment.weekdays(moment(Day).day())}</div>
      <div>{moment(Day).day()}</div>
  <div>{appState.WorkingHourOnDay[moment(Day).day()]}</div>*/}

      {!updatepossible ? (
        <>
          {/** if current day has too many hours added prevent more to be added by hiding current day input
           * Show a list of the day in question with the projects
           */}
          <DateHourErrorAlert
            Day={Day}
            updatepossible={updatepossible}
            datewitherrors={datewitherrors}
            currentdaysum={currentdaysum}
            deleteOnclick={deleteOnclick}
          />
        </>
      ) : (
        <label className={`day  ${!updatepossible ? "day__error" : ""}`} htmlFor={`${Dato}_${SelectedId}`}>
          <div className="day__name">
            <div>{moment.weekdays(moment(Day).day())}</div>
            <div className="day__date">{Dato}</div>
          </div>
          <div className="day__title ">
            <span className="day__title--container"></span>
          </div>
          {SelectedId && moment(Day).day() !== 0 && moment(Day).day() !== 6 ? (
            <>
              <i
                className="day__icon"
                style={{ cursor: "pointer" }}
                onClick={addFullDayOnProject}
                title={`Tilføj fuld dag ${appState.WorkingHourOnDay[moment(Day).day()]} timer`}
              >
                <FontAwesomeIcon icon={faStopwatch} />
              </i>
              <i className="day__icon" style={{ cursor: "pointer" }} onClick={deleteOnclick}>
                <FontAwesomeIcon icon={faTrash} />
              </i>
            </>
          ) : (
            <>
              <i></i>
              <i></i>
            </>
          )}

          <div className="day__summary">{SelectedId && <InputComponent Day={Day} Input={input} />}</div>
        </label>
      )}
    </>
  );
};

export default DayComponent;
