import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faUsers, faUserPlus, faTachometerAlt, faCalendar, faAddressCard, faTools } from "@fortawesome/free-solid-svg-icons";
import CommonNavs from "./CommonNavs";

const AdminHeaderNav = () => {
  const dashboard = <FontAwesomeIcon icon={faTachometerAlt} />;
  const users = <FontAwesomeIcon icon={faUsers} />;
  const calender = <FontAwesomeIcon icon={faCalendar} />;
  const newuser = <FontAwesomeIcon icon={faUserPlus} />;
  const customer = <FontAwesomeIcon icon={faAddressCard} />;
  const jobs = <FontAwesomeIcon icon={faTools} />;

  return (
    <>
      <CommonNavs.CommonNavsSignedIn />
      <ul className="sidebarnav">
        <li className="nav-item">
          <a className="nav-link nav-link--navitems nav-link__leftborder--orange" href="/users">
            {users} Alle aktive ansatte
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link nav-link--navitems nav-link__leftborder--orange" href="/newuser">
            {newuser} Tilføj ny ansat
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link nav-link--navitems nav-link__leftborder--orange" href="/timeseddel">
            {calender} Nye timesedler
          </a>
        </li>

        <li className="nav-item">
          <a className="nav-link nav-link--navitems nav-link__leftborder--midblue" href="/customers">
            {customer} Alle aktive kunder{" "}
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link nav-link--navitems nav-link__leftborder--midblue" href="/jobs">
            {jobs} Alle aktive jobs
          </a>
        </li>
      </ul>
    </>
  );
};

export default AdminHeaderNav;
