import React from "react";

import Page from "./Page";

const HomeGuest = () => {
  return (
    <Page
      title="Home page"
      description="velkomstside for test"
      className="container container--narrow py-md-5"
    >Home guest</Page>
  );
};

export default HomeGuest;
