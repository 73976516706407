import React from "react";

import InputComponent from "./InputComponent";

import moment from "moment";
import "moment/locale/da";

const ShowResultProjectAsWeek = ({ mycurrentjobs, GetWeekContainingValue, NominelHours }) => {
  let totalhours = 0;

  return (
    <article className="all">
      {mycurrentjobs.map((jobs, i) => (
        <table className="all__week" key={i}>
          <thead>
            <tr>
              <th colSpan="3">Uge {Object.keys(jobs)}</th>
            </tr>
            <tr>
              <th>Dato</th>
              <th>Title</th>
              <th>Timer</th>
            </tr>
          </thead>
          <tbody>
            {jobs[Object.keys(jobs)].map((job, y) => (
              <tr  key={y} onClick={() => GetWeekContainingValue(job.jobid, job.dato, job.jobtitle)}>
                <td>{job.dato.replaceAll(".", " - ")}</td>
                <td>{job.jobtitle}</td>
                <td  style={{ textAlign: "right" }}>
                  <InputComponent Day={moment(job.dato, "D-M-YYYY")} Input={job.timer} jobid={job.jobid} />
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td style={{ textAlign: "right" }}>Samlet for uge {Object.keys(jobs)} </td>
              <td style={{ textAlign: "right", fontWeight: "bold" }}>
                <u>
                  {
                    (totalhours = jobs[Object.keys(jobs)].reduce((acc, val) => {
                      return (acc += Number(val.timer));
                    }, 0))
                  }
                </u>
              </td>
            </tr>
            {totalhours - NominelHours > 0 ? (
              <tr>
                <td></td>
                <td style={{ textAlign: "right" }}>Her af overarbejde</td>
                <td style={{ textAlign: "right", fontWeight: "bold" }}>{totalhours - NominelHours}</td>
              </tr>
            ) : (
              <></>
            )}
          </tfoot>
        </table>
      ))}
    </article>
  );
};

export default ShowResultProjectAsWeek;
