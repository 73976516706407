import React, { useEffect, useState, useContext } from "react";

import StateContext from "../StateContext";
import DispatchContext from "../DispatchContext";

import moment from "moment";
import "moment/locale/da";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";

import Page from "../components/Page";

import DayComponent from "./DayComponent";

import DropDownContentSwitcher from "./DropDownContentSwitcher";
import DropDownComponent from "./DropDownComponent";

import ShowResultProjectAsProject from "./ShowResultProjectAsProject";

import ShowResultProjectAsWeek from "./ShowResultProjectAsWeek";

import LoadingDotsIcons from "../components/common/LoadingDotsIcons";

import ConvertStringToDate from "./ConvertStringToDate";

import ToggleClass from "./ToggleClass";

const TimeSheet = () => {
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);

  const SelectedId = appState.SelectedId;

  const calender = [];
  const [value, setValue] = useState(moment());

  const [isloading, setIsloading] = useState(false);

  const [searchterm, setSearchTerm] = useState("jobtitle");

  const [mycurrentjobs, sortCurrentJobsBySelection] = useState([]);

  const useruid = appState.user.user.uid;

  const jobArr = appState.objArr;

  const startDay = value.clone().startOf("week");
  const endDay = value.clone().endOf("week");

  const day = startDay.clone().subtract(1, "day");

  const SelectId = appState.SelectedId;

  const weeknum = moment(day).format("w");

  while (day.isBefore(endDay, "day")) {
    calender.push(
      Array(7)
        .fill(0)
        .map(() => day.add(1, "day").clone())
    );
  }

  const prevWeek = () => {
    appDispatch({ type: "setClicks" });
    return value.clone().subtract(1, "week");
  };
  const nextWeek = () => {
    appDispatch({ type: "setClicks" });
    return value.clone().add(1, "week");
  };

  const preventFutureWeekNum = () => {
    return moment(day).format("w") < moment().format("w") ? "visible" : "hidden";
  };

  const GetWeekContainingValue = (id, dato, title) => {
    appDispatch({ type: "selectedid", value: id });
    appDispatch({ type: "selecttitle", value: title });
    appDispatch({ type: "selectedtitle", value: title });
  };

  //show distinct values
  //sort between week and project
  useEffect(() => {
    const currentJobs = [];

    let term = searchterm;

    const myjobs = undefined !== jobArr ? [...new Set(jobArr[0][useruid].map((x) => x[term]))] : [];

    for (let jobindex in myjobs) {
      currentJobs.push({ [myjobs[jobindex]]: [] });

      let arrId = jobArr[0][useruid];

      for (let index in arrId) {
        if (arrId[index][term] === myjobs[jobindex]) {
          currentJobs[jobindex][myjobs[jobindex]].push(arrId[index]);

          currentJobs[jobindex][myjobs[jobindex]] = currentJobs[jobindex][myjobs[jobindex]].sort(function (a, b) {
            if (ConvertStringToDate(a.dato).getTime() / 1000 < ConvertStringToDate(b.dato).getTime() / 1000) return -1;
            if (ConvertStringToDate(a.dato).getTime() / 1000 > ConvertStringToDate(b.dato).getTime() / 1000) return 1;
            return 0;
          });
        }
      }
    }

    //console.log(currentJobs);
    sortCurrentJobsBySelection(currentJobs);
  }, [appState.objArr, appState.SelectedTitle, SelectId, appState.setClicks, searchterm]);

  useEffect(() => {
    if (!SelectId && jobArr[0][useruid].length > 0) {
      const latestSheet = jobArr[0][useruid].reduce((a, b) => {
        return new Date(a.createdAt) > new Date(b.createdAt) ? a : b;
      });

      appDispatch({ type: "selectedid", value: latestSheet["jobid"] });
      appDispatch({ type: "selecttitle", value: latestSheet["jobtitle"] });
      appDispatch({ type: "selectedtitle", value: latestSheet["jobtitle"] });
      appDispatch({ type: "setClicks" });
    }
  }, []);

  useEffect(() => {
    setIsloading(true);

    const rerender = () => {
      setIsloading(false);
    };

    setTimeout(rerender, 500);
  }, [appState.objArr, SelectId, weeknum, appState.setClicks]);

  return (
    <Page title="Time" >
      <section className="timesheet">
        <div className="container">
          <div className="days-container">
            <section>
              <h1>Timeseddel</h1>

              <div className="weeknum">
                <h4>
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} onClick={() => setValue(prevWeek())} />
                </h4>
                <h2>{moment(day).format("w")}</h2>
                <h4>
                  {/*prevent choosing weeks into the future add invisible 
              placeholdericon in case scrolling is not allowed*/}

                  <FontAwesomeIcon style={{ visibility: preventFutureWeekNum() }} icon={faArrowAltCircleRight} onClick={() => setValue(nextWeek())} />
                </h4>
              </div>

              <DropDownContentSwitcher />
              <DropDownComponent />

              <div className="week">
                <div className="watermark">{moment(day).format("w")}</div>

                {isloading ? <LoadingDotsIcons /> : calender[0].map((day, i) => <DayComponent Day={day} key={i} />)}
              </div>

              <div className="sum">
                <div className="sum__total">
                  {SelectedId && (
                    <div>
                      <span>
                        For {appState.SelectedTitle} i ugen = {appState.ProjectInWeekSum}
                      </span>
                    </div>
                  )}
                  <div>
                    <span>Timer ialt for uge = {appState.WeekSum}</span>
                    {appState.WeekSum - appState.nominelWeekHours > 0 ? (
                      <p>Overarbejde totalt: {appState.WeekSum - appState.nominelWeekHours} timer </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>
                    <span>Total sum af timer = {appState.TotalHours}</span>
                  </div>
                </div>
              </div>
            </section>
            {jobArr && jobArr[0][useruid].length > 0 ? (
              <section>
                <header>
                  <h2 style={{ textAlign: "center" }}>Opsummering af indtastning</h2>
                </header>
                <div style={{ textAlign: "center" }} className="button__select">
                  <button
                    value="jobtitle"
                    classvalue="sumbutton"
                    className="sumbutton active"
                    onClick={(e) => {
                      setSearchTerm(e.target.value);
                      ToggleClass(e);
                    }}
                  >
                    På projekt basis
                  </button>{" "}
                  <button
                    value="uge"
                    classvalue="sumbutton"
                    className="sumbutton "
                    onClick={(e) => {
                      setSearchTerm(e.target.value);
                      ToggleClass(e);
                    }}
                  >
                    På uge basis
                  </button>
                </div>

                {searchterm && searchterm === "jobtitle" ? (
                  <ShowResultProjectAsProject mycurrentjobs={mycurrentjobs} GetWeekContainingValue={GetWeekContainingValue} />
                ) : (
                  <ShowResultProjectAsWeek
                    mycurrentjobs={mycurrentjobs}
                    GetWeekContainingValue={GetWeekContainingValue}
                    NominelHours={appState.nominelWeekHours}
                  />
                )}
              </section>
            ) : (
              <article></article>
            )}
          </div>
        </div>
      </section>
    </Page>
  );
};

export default TimeSheet;
