import React, { useContext } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faSignInAlt, faSignOutAlt, faTachometerAlt, faCalendarDay, faInfo, faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";

import DispatchContext from "../../DispatchContext";

import StateContext from "../../StateContext";

const login = <FontAwesomeIcon icon={faSignInAlt} />;
const logout = <FontAwesomeIcon icon={faSignOutAlt} />;
const dashboard = <FontAwesomeIcon icon={faTachometerAlt} />;
const timecard = <FontAwesomeIcon icon={faCalendarDay} />;
const info = <FontAwesomeIcon icon={faInfo} />;
const contact = <FontAwesomeIcon icon={faEnvelopeOpen} />;

const CommonNavsSignedIn = () => {
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);

  const currentUser = appState.user;

  const handleLogout = () => {
    appDispatch({ type: "logout" });
    appDispatch({ type: "flashMessage", value: "På gensyn " });
    localStorage.removeItem("user");
  };

  return (
    <nav>
      <ul className="sidebarnav">
        <li className="sidebarnav__image">
          <img src={`https://netcrawler.dk/${currentUser.user.avatar}`} alt={`profil billede af ${currentUser.user.name}`} />
        </li>

        <li className="sidebarnav__title">
          <h4 className="text-center my-0 mr-md-auto font-weight-normal">{currentUser.user.uid}</h4>
        </li>
        <li>{appState.loggedIn && <button onClick={handleLogout}>{logout} logout</button>}</li>
        <li className="nav-item">
          <a className="nav-link nav-link--navitems nav-link__leftborder--red" href="/dashboard">
            {dashboard} Dashboard
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link nav-link--navitems nav-link__leftborder--red" href="/timeseddel">
            {timecard} Timeseddel
          </a>
        </li>
        <li></li>
      </ul>
    </nav>
  );
};

const CommonNavsSignedOut = () => {
  return (
    <ul className="sidebarnav">
      <li>
        <img src="https://netcrawler.dk/uploads/images/avatars/mst_logo.svg" alt="Mestertømrerens logo" width="90px" height="90px" />
      </li>
      <li className="nav-item">
        <a className="nav-link nav-link--navitems nav-link__leftborder--red" href="/login">
          {login} <span>Log in</span>
        </a>
      </li>

      <li className="nav-item">
        <a className="nav-link nav-link--navitems nav-link__leftborder--red" href="/om">
          {info} <span>Om os</span>
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link nav-link--navitems nav-link__leftborder--red" href="/om">
          {info} <span>Kontakt os</span>
        </a>
      </li>
    </ul>
  );
};

export default { CommonNavsSignedIn, CommonNavsSignedOut };
