const CheckRole = (Role, users) => {
  //user profile stored in localstorge when user logs in
  const user = users;

  // check if user is logged in
  if (user && user.token) {
    // get users roles
    let check = user.roles;

    //loop through users roles to check if the one in props is pressent
    for (let key in check) {
      if (check[key].name === Role) {
        // if role is present return true
        return true;
      }
    }

    // else return false
    return false;
  }
};

export default CheckRole;
