import React, { useEffect } from "react";

import Container from "./Container";

const Page = (props) => {
  //console.log(props);

  useEffect(() => {
    document.title = `${props.title} | Timeseddel-APP`;
    window.scrollTo(0, 0);
    if (props.description) {
      document.querySelector('meta[name="description"]').setAttribute("content", props.description);
    }
  }, [props.title, props.description]);

  return <Container>{props.children}</Container>;
};

export default Page;
