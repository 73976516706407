import React, { useContext, useState, useEffect } from "react";

import StateContext from "../StateContext";

import DispatchContext from "../DispatchContext";

import authHeader from "../services/auth-header";

import axios from "axios";

import LoadingDotsIcons from "../components/common/LoadingDotsIcons";

const DropDownComponent = () => {
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);

  const currentUser = appState.user;

  const [jobs, setJobs] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const url = `https://netcrawler.dk/api/${appState.SelectedValue}`;

      try {
        const result = await axios(url, {
          headers: authHeader(currentUser),
        });

        setJobs(result.data[appState.SelectedValue]);
        appDispatch({ type: "setClicks" });
        //reset selectdropdown
        document.getElementById(appState.SelectedValue).selectedIndex = 0;
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [appState.SelectedValue]);

  const changeSelect = (e) => {
    var index = e.nativeEvent.target.selectedIndex;
    //set select title
    appDispatch({ type: "selectedid", value: e.target.value });
    appDispatch({ type: "selectedtitle", value: e.nativeEvent.target[index].text });
    appDispatch({ type: "setClicks" });
  };

  return (
    <>
      {jobs !== undefined ? (
        <select className="select" id={appState.SelectedValue} name={appState.SelectedValue} onInput={changeSelect}>
          <option value="">{appState.SelectTitle}</option>
          {jobs.map((job) => (
            <option key={job.uuid} value={job.uuid}>
              {job.name}
            </option>
          ))}
        </select>
      ) : (
        <LoadingDotsIcons />
      )}
    </>
  );
};

export default DropDownComponent;
