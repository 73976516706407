import React, { Suspense, lazy, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import { useImmerReducer } from "use-immer";

import StateContext from "./StateContext";

import DispatchContext from "./DispatchContext";
import Footer from "./components/LayoutComponents/Footer";
import FlashMessages from "./components/FlashMessages";
import Header from "./components/LayoutComponents/Header";
import HomeGuest from "./components/HomeGuest";

//import SignUp from "./components/SignUp";

import Login from "./components/Login";

import "./App.scss";

// import component 👇
import Drawer from "react-modern-drawer";

//import styles 👇
import "react-modern-drawer/dist/index.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faBars } from "@fortawesome/free-solid-svg-icons";

import LoadingDotsIcons from "./components/common/LoadingDotsIcons";

import TimeSheet from "./TimeSheet/TimeSheet";

const Home = lazy(() => import("./components/Home"));
const NotFound = lazy(() => import("./components/NotFound"));

function App() {
  let navigate = useNavigate();

  const burgermenu = <FontAwesomeIcon icon={faBars} />;

  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const currentUser = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).user : "";

  const storageExsist = localStorage.getItem("timesheet-" + currentUser.uid)
    ? JSON.parse(localStorage.getItem("timesheet-" + currentUser.uid))
    : [{ [currentUser.uid]: [] }];

  const initialState = {
    loggedIn: Boolean(localStorage.getItem("user")),
    flashMessageges: [],
    user: JSON.parse(localStorage.getItem("user")),
    objArr: storageExsist,
    workPeriodTotalSum: null,
    setClicks: [],
    nominelWeekHours: 37,
    maxWorkingHoursDay: 16,
    WorkingHourOnDay: [0, 7.75, 7.75, 7.75, 7.75, 6, 0],
    SelectedId: "",
    SelectedValue: "absencejobs",
    SelectedTitle: "",
    SelectTitle: "Vælg relevant job her",
    TotalHours: null,
    WeekSum: null,
    DaySum: null,
    ProjectInWeekSum: null,
  };

  const ourReducer = (draft, action) => {
    switch (action.type) {
      case "login":
        draft.loggedIn = true;

        navigate(`/dashboard`);
        return;
      case "user":
        draft.user = JSON.parse(localStorage.getItem("user"));
        return;

      case "logout":
        draft.loggedIn = false;
        navigate(`/login`);
        return;

      case "flashMessage":
        draft.flashMessageges.push(action.value);
        return;

      case "selectval":
        draft.SelectedValue = action.value;
        return;
      case "selectedid":
        draft.SelectedId = action.value;
        return;
      case "selectedtitle":
        draft.SelectedTitle = action.value;

        return;

      case "selecttitle":
        draft.SelectTitle = action.value;

        return;

      case "Change_Hours":
        draft.objArr[0][currentUser.uid][action.index].timer = action.value;
        draft.objArr[0][currentUser.uid][action.index].createdAt = Date.now();

        localStorage.setItem("timesheet-" + currentUser.uid, JSON.stringify(draft.objArr));

        return;

      case "Add_Hours":
        if (!localStorage.getItem("timesheet-" + currentUser.uid)) {
          localStorage.setItem("timesheet-" + currentUser.uid, JSON.stringify(draft.objArr));
        }

        draft.objArr[0][currentUser.uid].push(action.value);

        localStorage.setItem("timesheet-" + currentUser.uid, JSON.stringify(draft.objArr));

        return;

      case "Remove_Hours":
        draft.objArr[0][currentUser.uid].splice(action.index, 1);

        localStorage.setItem("timesheet-" + currentUser.uid, JSON.stringify(draft.objArr));
        return;

      case "WeekNum":
        draft.WeekNum = action.value;

        return;

      case "WeekSum":
        draft.WeekSum = action.value;

        return;

      case "ProjectInWeekSum":
        draft.ProjectInWeekSum = action.value;
        return;

      case "DaySum":
        draft.DaySum = action.value;
        return;

      case "TotalHours":
        draft.TotalHours = action.value;

        return;

      case "setClicks":
        draft.setClicks++;
        return;

      default:
        return;
    }
  };

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  return (
    <>
      <StateContext.Provider value={state}>
        <DispatchContext.Provider value={dispatch}>
          <FlashMessages messages={state.flashMessageges} />

          <button onClick={toggleDrawer} aria-label="sliding_menu">
            {burgermenu}
          </button>
          <Drawer open={isOpen} onClose={toggleDrawer} direction="left">
            <Header />
          </Drawer>

          <Suspense fallback={<LoadingDotsIcons />}>
            <Routes>
              <Route path="/home" element={state.loggedIn ? <Home /> : <HomeGuest />} />
              <Route path="/" element={state.loggedIn ? <Home /> : <HomeGuest />} />

              <Route path="/timeseddel" element={state.loggedIn ? <TimeSheet /> : <NotFound />} />

              <Route path="/login" element={<Login />} />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
          <Footer />
        </DispatchContext.Provider>
      </StateContext.Provider>
    </>
  );
}

export default App;
