import React from "react";

const ToggleClass = (e) => {
  //  console.log(e);
  //console.log(e.target.attributes.classvalue.value);

  let classes = e.target.attributes.classvalue.value;
  let els = document.getElementsByClassName(classes + " active");
  if (els) {
    while (els[0]) {
      els[0].classList.remove("active");
    }
  }
  e.target.className = classes.replace(classes, classes + " active");
};

export default ToggleClass;
