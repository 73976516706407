import React, { useContext } from "react";

import StateContext from "../StateContext";

import DispatchContext from "../DispatchContext";

import ToggleClass from "./ToggleClass";

const DropDownContentSwitcher = () => {
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);



  const onclickHandler = (e) => {
    if (e.target.value !== appState.SelectedValue) {
      appDispatch({ type: "selecttitle", value: e.target.title });

      appDispatch({ type: "selectval", value: e.target.value });

      ToggleClass(e);
      appDispatch({ type: "selectedid", value: "" });
      appDispatch({ type: "setClicks" });
    }
  };

  return (
    <div className="button__select">
      <button className="button active" value="absencejobs" classvalue="button" title="Vælg relevant job her" onClick={onclickHandler}>
        projekter
      </button>
      <button className="button" value="absencetime" classvalue="button" title="Vælg relevant fravær her" onClick={onclickHandler}>
        intern fravær
      </button>
    </div>
  );
};

export default DropDownContentSwitcher;
