import React, { useContext } from "react";

import StateContext from "../../StateContext";
import DispatchContext from "../../DispatchContext";

import CheckRole from "../common/CheckRole";
import AdminHeaderNav from "../Navs/AdminHeaderNav";
import HeaderNav from "../Navs/HeaderNav";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faSignInAlt, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const login = <FontAwesomeIcon icon={faSignInAlt} />;
  const logout = <FontAwesomeIcon icon={faSignOutAlt} />;

  const appDispatch = useContext(DispatchContext);

  const handleLogout = () => {
    appDispatch({ type: "logout" });
    appDispatch({ type: "flashMessage", value: "På gensyn " });
    localStorage.removeItem("user");
  };

  const appState = useContext(StateContext);

  const user = appState.user;

  return (
    <>
      <header className="sidebarnav">
        <div>{CheckRole("Admin", appState.user) && appState.loggedIn ? <AdminHeaderNav /> : <HeaderNav />}</div>
      </header>
    </>
  );
};

export default Header;
