import React from "react";

import InputComponent from "./InputComponent";

import moment from "moment";
import "moment/locale/da";

const ShowResultProjectAsProject = ({ mycurrentjobs, GetWeekContainingValue }) => {
  //console.log('jobs ' ,mycurrentjobs)

  return (
    <article className="current">
      {mycurrentjobs.map((jobs, i) => (
        <table className="current__jobs" key={i}>
          <thead>
            <tr>
              <th colSpan="3">{Object.keys(jobs)}</th>
            </tr>
            <tr>
              <th>Dag</th>

              <th>Dato</th>
              <th>Timer</th>
            </tr>
          </thead>
          <tbody>
            {jobs[Object.keys(jobs)].map((job, y) => (
              <tr key={y} onClick={() => GetWeekContainingValue(job.jobid, job.dato, job.jobtitle)}>
                <td>{job.dag}</td>
                <td>
                  <small>{job.dato}</small>
                </td>
                <td style={{ textAlign: "right" }}>
                  <InputComponent Day={moment(job.dato, "D-M-YYYY")} Input={job.timer} jobid={job.jobid} />
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td style={{ textAlign: "right" }}>Samlet</td>
              <td style={{ textAlign: "right", fontWeight: "bold" }}>
                <u>
                  {jobs[Object.keys(jobs)].reduce((acc, val) => {
                    return (acc += Number(val.timer));
                  }, 0)}
                </u>
              </td>
            </tr>
          </tfoot>
        </table>
      ))}
    </article>
  );
};

export default ShowResultProjectAsProject;
