import React from "react";

import InputComponent from "./InputComponent";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const DateHourErrorAlert = ({ Day, updatepossible, datewitherrors, currentdaysum, deleteOnclick }) => {
  return (
    !updatepossible &&
    datewitherrors !== undefined && (
      <section>
        <header style={{ textAlign: "center" }}>
          <h3>Fejl i en af disse indtastninger</h3>
          <p>
            Samlet{" "}
            <u>
              <b>{currentdaysum}</b>
            </u>{" "}
            timer på samme dag
          </p>
        </header>

        <div className="day">
          <div>Dato</div>
          <div>Project</div>
          <div></div>
          <div></div>
          <div style={{ textAlign: "left" }}>Timer</div>
        </div>

        {datewitherrors.map((dateerror, i) => {
          const delJobid = dateerror.jobid;
          const delDate = dateerror.dato;
          return (
            <div className="day" key={i}>
              <div>
                <span>
                  <b>{dateerror.dag.toUpperCase()}</b>
                </span>
                <br />
                <small>{dateerror.dato}</small>
              </div>
              <div>{dateerror.jobtitle}</div>
              <div></div>
              <div>
                <i className="day__icon" style={{ cursor: "pointer" }} onClick={() => deleteOnclick(delJobid, delDate)}>
                  <FontAwesomeIcon icon={faTrash} />
                </i>
              </div>
              <div className="day__summary">
                <InputComponent Day={Day} Input={dateerror.timer} jobid={dateerror.jobid} />
              </div>
            </div>
          );
        })}

        <hr />
        <hr />
      </section>
    )
  );
};

export default DateHourErrorAlert;
