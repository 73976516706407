import React, { useState, useContext } from "react";
import Axios from "axios";

import DispatchContext from "../DispatchContext";
import Page from "./Page";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faUser, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
  const fauser = <FontAwesomeIcon icon={faUser} />;
  const passw = <FontAwesomeIcon icon={faUnlockAlt} />;

  const appDispatch = useContext(DispatchContext);

  const [uid, setUid] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await Axios.post("https://netcrawler.dk/api/login", {
        uid,
        password,
      });

      if (response.data) {
        //console.log(response.data.user);

        localStorage.setItem("user", JSON.stringify(response.data));

        appDispatch({ type: "user" });

        appDispatch({ type: "login" });

        appDispatch({ type: "flashMessage", value: "Du er logget in" });
      }
    } catch (e) {
      appDispatch({ type: "flashMessage", value: "Vi kan ikke finde nogle brugere med disse kriterier" });
      console.log(e);
    }
  };
  return (
    <Page title="login" description="login for mestertømreren">
      <section className="login">
        <article>
          <img src="https://netcrawler.dk/uploads/images/avatars/mst_logo.svg" alt="Mestertømrerens logo" width="140px" height="140px" />
        </article>

        <form onSubmit={handleSubmit}>
          <h1>Log in</h1>
          <div className="form-group">
            <label htmlFor="uid">
              <small>Brugernavn</small>
            </label>
            <div className="form-element">
              <input
                type="text"
                name="uid"
                onChange={(e) => {
                  setUid(e.target.value);
                }}
                autoComplete="current-password"
              />
              {fauser}
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="password">
              <small>Brugernavn</small>
            </label>
            <div className="form-element">
              <input
                type="password"
                name="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              {passw}
            </div>
          </div>
          <div className="form-group">
            <input className="btn btn-info" type="submit" value="Login" />
          </div>
          <small>
            <a href="/signup"> Sign up </a>
          </small>
        </form>
      </section>
    </Page>
  );
};

export default Login;
